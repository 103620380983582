@media print {
  .leaflet-container {
    width: 500mm;
    //height: 190mm;
  }
  .leaflet-control-container {
    display: none;
  }
}

@page {
  margin: 0.5cm;
  size: landscape;
}