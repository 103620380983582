.leaflet-tooltip {
  &.nefco-tooltip,
  &.mote-wb-tooltip {
    width: 250px;
    white-space: normal;
    .project-title {
      margin-bottom: 3px;
      font-weight: bold;
      text-align: center;
    }
    &.nefco-tooltip {
      .project-title {
        color: #33a02c;
      }

    }
    &.mote-wb-tooltip {
      .project-title {
        color: #a1cef8;
      }
    }
  }
}

.icon-diameter,
.icon-layingMeth {
  position: relative;
  top: 1px;
  font-size: 1.55em;
}
