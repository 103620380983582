.ant-collapse-header-text {
  font-weight: bold;
}

.circle {
  position: relative;
  display: inline-block;
  top: 3px;
  right: 10px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

.listUnStyle {
  list-style: none;
}

.circle-1 {
  background-color: #C2DB27;
}

.circle-2 {
  background-color: #2A5485;
}

.circle-3 {
  background-color: #028E9B;
}

.circle-4 {
  background-color: #FF0D00;
}

.circle-5 {
  background-color: #779E00;
}

.circle-6 {
  background-color: #987C65;
}

.circle-7 {
  background-color: #A43DD4;
}

.circle-8 {
  background-color: #4D6DE0;
}

.circle-tec {
  background-color: #FC4D4D;
}

.line-legend {
  position: relative;
  top: -4px;
  right: 10px;
  display: inline-block;
  width: 30px;
  height: 2px;
  background-color: #7e3131;
}

.line-1 {

}

.line-2 {
  top: -2px;
  height: 5px;
}

.line-3 {
  background-color: white;

  .short {
    margin-right: 3px;
    background-color: #317e42;
    display: inline-block;
    width: 3px;
    height: 2px;
  }
}

.legend-icon {
  position: relative;
  left: -10px;
}
.legend-icon-building {
  top: 10px;
}
.legend-icon-bolt {
  top: 2px;
}
.legend-icon-boiler {
top: 5px;
}
