.leaflet-container {
  width: 100%;
  height: 100vh;
}

@import "var";
@import "mixins";
@import "print";
@import "tooltip";
@import "popup-boilers";
@import "popup-border-schema";
@import "popup-buildings";
@import "legend";

.btn-print {
  position: relative;
  z-index: 9999999999999999999;
  left: 10px;
  top: 80px;
}